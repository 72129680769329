import { useEffect, useState } from "react";
import Button from "../components/button/Button.tsx";
import SingleUser from "../components/settings/single-user.tsx";
import axiosInstance from "../utils/axios-instance.ts";
import { toast } from "react-toastify";
import { ParseErrors } from "../utils/parse-errors.ts";
import UseAuth from "../utils/use-auth.ts";
import { settingsNavData } from "../components/settings/settings-data.ts";
import clsx from "clsx";
import UserSettingsTab from "../components/settings/user-settings.tsx";
import PaymentSettings from "../components/settings/payment-settings.tsx";
import AgencySettings from "../components/settings/agency-settings.tsx";
import { UserModelType } from "../types/user-types.ts";
import PaginationMenu from "../components/pagination/paginationMenu.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import GptSettings from "../components/settings/gpt-settings.tsx";
import Header from "../layout/Header.tsx";


interface IUsers {
  totalCount: number;
  data: UserModelType[]
}

const UserSettings = () => {
  const { user } = UseAuth()
  const [active, setActive] = useState<"user-settings" | "agency-users" | "agency-settings" | "gpt-settings" | "payment-settings">("user-settings")
  const [showUserForm, setShowUserForm] = useState<boolean>(false);
  const [users, setUsers] = useState<IUsers>({ totalCount: 0, data: [] })
  const [limit,] = useState(10)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState("")
  const [newUserValues, setNewUserValues] = useState<Record<string, any>>({})
  const [loading, setLoading] = useState({
    isUpdatingUser: false,
    isCreatingUser: false,
    isFetchingUser: false
  });


  const HandleCreateUser = async () => {
    if (validate()) {
      setLoading((prev) => ({ ...prev, isCreatingUser: true }))
      try {
        const res = await axiosInstance.post('/auth/create', { ...newUserValues })
        toast.success("User Added Successfully")
        setUsers((prev) => ({ totalCount: prev.totalCount + 1, data: [...prev.data, res.data.data] }))
        setShowUserForm(false)
        setLoading((prev) => ({ ...prev, isCreatingUser: false }))
      } catch (error) {
        console.log(error)
        toast.error(ParseErrors(error))
        setLoading((prev) => ({ ...prev, isCreatingUser: false }))
      }
    }
  }


  useEffect(() => {
    const getUsers = async () => {
      setLoading((prev) => ({ ...prev, isFetchingUser: true }))
      try {
        const res = await axiosInstance.get(`/user/my/agency?page=${page}&limit=${limit}${search ? `&name=${search}` : ''}`)
        setUsers(res.data)
        setLoading((prev) => ({ ...prev, isFetchingUser: false }))
      } catch (error) {
        console.log(error)
        setLoading((prev) => ({ ...prev, isFetchingUser: false }))
      }
    }

    user?.type === "agency" && getUsers()
  }, [user?.type, page, limit, search])


  const HandleNewUserValues = (e) => {
    setNewUserValues((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }


  const validate = () => {
    if (!newUserValues.name) {
      toast.error("Please enter name")
      return false
    } else if (!newUserValues.email) {
      toast.error("Please enter email address")
      return false
    } else if (!newUserValues.email?.includes('@')) {
      toast.error("Please enter a valid email address")
      return false
    } else if (!newUserValues.password) {
      toast.error("Please enter password")
      return false
    } else if (newUserValues?.password !== newUserValues?.cpassword) {
      toast.error("Please enter a valid password")
      return false
    } else {
      return true
    }
  }

  const HandleChangePage = (page: number) => {
    setPage(page);
  }

  return (
    <div className="max-w-[1440px] mx-auto">
      <Header className={"bg-[#C8E3FF]"} />
      <div className="py-10 bg-white ">
        <div className="mx-auto w-[95%]  flex items-center justify-center gap-x-6">
          {
            settingsNavData.map((item) => {
              return item.permissions?.includes(user?.type) ? <Button onClick={() => setActive(item.id as any)} className={clsx("!inline-block w-[auto] h-[50px] !px-[50px] !rounded-full !text-md !font-[400]", item.id !== active && "bg-gray-300 !text-black")} >{item.label}</Button> : null
            })
          }
        </div>
      </div>
      <div className="min-h-screen bg-white">

        {/* --------------------- USER SETTINGS ----------------------- */}
        {active === "user-settings" ? <UserSettingsTab /> : null}
        {/* --------------------- USER SETTINGS ----------------------- */}

        {/* --------------------- Payment SETTINGS ----------------------- */}
        {active === "payment-settings" && user.type === "agency" ? <PaymentSettings /> : null}
        {/* --------------------- Payment SETTINGS ----------------------- */}

        {/* --------------------- AGENCY SETTINGS ----------------------- */}
        {active === "agency-settings" && user.type === "super-admin" ? <AgencySettings /> : null}
        {/* --------------------- AGENCY SETTINGS ----------------------- */}

        {/* --------------------- AGENCY SETTINGS ----------------------- */}
        {active === "gpt-settings" && user.type === "super-admin" ? <GptSettings /> : null}
        {/* --------------------- AGENCY SETTINGS ----------------------- */}



        {/* --------------------- NEW USERS DATA ----------------------- */}
        {user?.type === "agency" && active === "agency-users" && <div className="w-[50%] mx-auto">
          <div className="w-[90%] mx-auto">
            <div className="flex flex-col items-start">
              <div className="w-full flex items-center justify-between mb-4">
                <h2 className="text-xl text-center text-blue font-[600] ">Agency Users <span>( {users.totalCount} )</span></h2>
                <input placeholder="Search" onChange={(e) => setSearch(e.target.value.toLocaleLowerCase())} className="h-[45px] w-[50%] border-[1px] border-gray-200 rounded-md outline-none px-4 text-md font-[400] " />
              </div>

              {showUserForm && <div className="w-full grid grid-cols-2 gap-6 mb-6 py-4">
                <input placeholder="User Name" name="name" onChange={HandleNewUserValues} className="h-[45px] w-full border-[1px] border-gray-200 rounded-md outline-none px-4 text-md font-[400] " />
                <input placeholder="Email" name="email" onChange={HandleNewUserValues} className="h-[45px] w-full border-[1px] border-gray-200 rounded-md outline-none px-4 text-md font-[400] " />
                <input placeholder="Password" name="password" onChange={HandleNewUserValues} className="h-[45px] w-full border-[1px] border-gray-200 rounded-md outline-none px-4 text-md font-[400] " />
                <input placeholder="Confirm Password" name="cpassword" onChange={HandleNewUserValues} className="h-[45px] w-full border-[1px] border-gray-200 rounded-md outline-none px-4 text-md font-[400] " />
              </div>}

              <Button isLoading={loading.isCreatingUser} disabled={loading.isCreatingUser} className="max-w-[100%]" onClick={() => {
                if (!showUserForm) {
                  setShowUserForm(true)
                } else {
                  HandleCreateUser()
                }
              }}>{showUserForm ? "Submit" : "Add User"}</Button>
            </div>

            {/* ------------------------ USERS LIST --------------------- */}
            <div className="w-full mt-[50px]">
              {loading.isFetchingUser ? <div className="w-full h-[300px] flex items-center justify-center">
                <FontAwesomeIcon icon={faSpinner} spin className="text-[5rem] text-blue" />
              </div> :
                users.data?.map((item) => {
                  return (
                    <SingleUser key={item?.id} item={item} />
                  )
                })
              }

              <div className="mt-8">
                <PaginationMenu totalCount={users.totalCount} pageSize={limit} currentPage={page} onPageChange={HandleChangePage} />
              </div>
            </div>
          </div>
        </div>}
        {/* --------------------- NEW USERS DATA ----------------------- */}
      </div>
     
    </div>
  );
};

export default UserSettings;
