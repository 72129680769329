export const settingsNavData = [
  {
    id: "user-settings",
    label: "User Settings",
    permissions: ["super-admin", "agency", "user"],
  },
  {
    id: "agency-users",
    label: "Agency Users",
    permissions: ["agency"],
  },
  {
    id: "payment-settings",
    label: "Payment Settings",
    permissions: ["agency"],
  },
  {
    id: "agency-settings",
    label: "Agency Settings",
    permissions: ["super-admin"],
  },
  {
    id: "gpt-settings",
    label: "AI Settings",
    permissions: ["super-admin"],
  },
];
