import moment from "moment";
import { UserAgencyModelType, UserModelType } from "../../types/user-types";
import CreateManualSubscriptionModel from "../../models/create-manual-subscription-model";
import { useEffect, useState } from "react";
import Button from "../button/Button";
import SingleUser from "./single-user";
import CreateNewUser from "../../models/create-new-user";
import axiosInstance from "../../utils/axios-instance";
import PaginationMenu from "../pagination/paginationMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { ParseErrors } from "../../utils/parse-errors";
import SuretyModel from "../../models/surety-model";

interface ISingleAgencyDetails {
  item: UserAgencyModelType;
}

const SingleAgencyDetails = ({ item }: ISingleAgencyDetails) => {
  const [users, setUsers] = useState<UserModelType[]>(item.users);
  const [isViewed, setIsViewed] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isSubscriptionModel, setIsSubscriptionModel] = useState(false);
  const [isUserModel, setIsUserModel] = useState(false);
  const [totalUsers, setTotalUsers] = useState(0);
  const [limit] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => {
    const getMoreUsers = async () => {
      try {
        const res = await axiosInstance.get(
          `/user/agency/${item.id}?limit=${limit}&page=${page}`
        );
        setTotalUsers(res.data.totalCount);
        setUsers(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    item.id && page && getMoreUsers();
  }, [page, limit, item.id]);

  const HandlePageChange = (page: number) => {
    setPage(page);
  };

  //    --------------------------------- [ TOGGLE MODELS ] -----------------------------------
  const toggleSubscriptionModel = () => {
    setIsSubscriptionModel((prev) => !prev);
  };

  const toggleViewModel = () => {
    setIsViewed((prev) => !prev);
  };

  const toggleUserModel = () => {
    setIsUserModel((prev) => !prev);
  };

  const toggleDeleteSuretyModel = () => {
    setIsDeletePopup((prev) => !prev);
  };

  // ----------------------------------------------- DELETE AGENCY ---------------------------------------------

  const handleDeleteAgency = async () => {
    setIsDeleting(true);
    try {
      const res = await axiosInstance.delete(
        `/auth/super/delete/agency/${item.id}`
      );
      toast.success(res.data.message);
      setIsDeleting(false);
      toggleDeleteSuretyModel()
    } catch (error) {
      console.log(error);
      setIsDeleting(false);
      toast.error(ParseErrors(error));
    }
  };

  return (
    <div className="w-full cursor-pointer">
      <div className="cursor-pointer grid grid-cols-6 gap-x-3 text-black bg-[rgba(250,250,250)] hover:bg-gray-100 py-4 px-6 border-b-[1px] border-gray-200">
        <th onClick={toggleViewModel} className="font-[500] text-md text-start">
          {item.name}
        </th>
        <th onClick={toggleViewModel} className="font-[500] text-md">
          {item.email}
        </th>
        <th onClick={toggleViewModel} className="font-[500] text-md">
          {item.subscription?.totalUsers || 0}
        </th>
        <th className="font-[500] text-md capitalize">
          {item.subscription?.type ? (
            item.subscription?.type === "manual" ? (
              <p
                className="text-blue underline"
                onClick={toggleSubscriptionModel}
              >
                Manual ( {item?.subscription?.plan} ) 
              </p>
            ) : (
              `${item.subscription?.type} ( ${item?.subscription?.plan} )`
            )
          ) : (
            <p
              className="text-blue underline"
              onClick={toggleSubscriptionModel}
            >
              Create Subscription
            </p>
          )}
        </th>
        <th onClick={toggleViewModel} className="font-[500] text-md capitalize">
          {item.subscription?.status || "None"}
        </th>
        <th className="font-[500] text-md text-end flex items-center justify-end">
          {moment(item.createdAt).format("DD-MM-YYYY")}{" "}
          <div className="ml-4 flex items-center justify-end">
            <button
              onClick={toggleDeleteSuretyModel}
              disabled={isDeleting}
              className="disabled:opacity-[0.5"
            >
              <FontAwesomeIcon
                icon={faTrash}
                color="red"
                className="text-lg cursor-pointer"
              />
            </button>
          </div>
        </th>
      </div>

      {/* -------------------------------- AGENCY DETAILS ---------------------------- */}
      {isViewed ? (
        <div className="w-full py-4">
          <div className="w-[95%] mx-auto">
            <div className="flex items-center justify-between">
              <h2 className="text-lg text-black font-[500]">
                Total Users{" "}
                <span className="text-blue">
                  ({totalUsers ? totalUsers : item.users.length})
                </span>
              </h2>
              <Button className="!w-[auto]" onClick={toggleUserModel}>
                Add User
              </Button>
            </div>

            <div className="mt-6">
              {users.map((item) => {
                return <SingleUser item={item} key={item.id} />;
              })}

              {page ? (
                <PaginationMenu
                  totalCount={totalUsers}
                  pageSize={limit}
                  currentPage={page}
                  onPageChange={HandlePageChange}
                />
              ) : users.length >= limit ? (
                <div className="w-full text-center mt-8">
                  <p
                    className="text-md text-blue underline cursor-pointer"
                    onClick={() => setPage(1)}
                  >
                    Show More
                  </p>{" "}
                  :
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}

      {/* -------------------------------- AGENCY DETAILS ---------------------------- */}
      {isDeletePopup ? <SuretyModel onClick={handleDeleteAgency} isLoading={isDeleting} toggleModel={toggleDeleteSuretyModel} /> : null}
      {isUserModel ? (
        <CreateNewUser
          toggleUserModel={toggleUserModel}
          agencyId={item.id}
          HandlePageChange={HandlePageChange}
        />
      ) : null}
      {isSubscriptionModel ? (
        <CreateManualSubscriptionModel
          isSubscription={item.subscription}
          toggleSubscriptionModel={toggleSubscriptionModel}
          agencyId={item.id}
        />
      ) : null}
    </div>
  );
};

export default SingleAgencyDetails;
